import React from 'react';
import cx from 'classnames';
import interviews from '../../interviews';
import SearchableVideo from '../SearchableVideo';
import SearchHelper from '../SearchHelper';

export default function Interviews() {
  const [init, setInit] = React.useState(true);
  const [firstInterview, setFirstInterview] = React.useState(null);
  const [showHelper, setShowHelper] = React.useState(false);
  const [helperTarget, setHelperTarget] = React.useState(null);
  const [showAllTags, setShowAllTags] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [tagFilter, setTagFilter] = React.useState(null);
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    const _tags = [];
    for (const interview of interviews) {
      for (const tag of interview.tags) {
        if (!_tags.includes(tag)) {
          _tags.push(tag);
        }
      }
    }
    setTags(_tags);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      const sortedInterviews = Array.from(document.querySelectorAll('.interview')).sort(
        (a: any, b: any) => {
          const x = a.offsetLeft > b.offsetLeft ? -1 : 0;
          const y = a.offsetTop < b.offsetTop ? -1 : 0;
          return a.style.order - b.style.order || x || y;
        }
      );
      setFirstInterview(sortedInterviews[0]);
    }, 500);
  }, [searchValue, tagFilter]);

  const updateTagFilter = (value) => {
    window.scrollTo({
      top: document.getElementById('interviews').offsetTop,
      left: 0,
      behavior: 'smooth',
    });
    setTagFilter(value);
  };

  return (
    <>
      {showHelper ? (
        <SearchHelper target={helperTarget} dismiss={() => setShowHelper(false)} />
      ) : null}
      <h2 className='text-2xl font-display px-6 mb-4'>Interviews</h2>
      <div className='p-6'>
        <input
          type='text'
          className='px-4 py-2 w-full border border-gray-300 text-gray-900 rounded-sm mb-4'
          placeholder='Search for keywords'
          value={searchValue}
          onChange={(e) => {
            if (init) {
              setInit(false);
              setShowHelper(true);
            }

            setSearchValue(e.target.value);
          }}
        />
        <div className={cx('relative overflow-hidden', { 'h-12': !showAllTags })}>
          {tags.map((t) => (
            <Tag
              key={t}
              value={t}
              tagFilter={tagFilter}
              setTagFilter={(value) => {
                setTagFilter(value);
              }}
            />
          ))}
          {!showAllTags ? (
            <>
              <div className='absolute top-0 left-0 w-full h-12 bg-gradient-to-b from-transparent to-gray-100'></div>
              <button
                onClick={() => setShowAllTags(true)}
                className='absolute bottom-1 left-1/2 transform -translate-x-1/2 border bg-blue-400 rounded px-2 py-1 text-sm hover:bg-blue-500 transition duration-100 text-gray-100 filter drop-shadow'
              >
                Search by tags
              </button>
            </>
          ) : null}
        </div>
      </div>
      <div id='interviews' className='p-6 grid md:grid-cols-2 sm:grid-cols-1 gap-12'>
        {interviews.map((v) => (
          <SearchableVideo
            key={v.url}
            {...v}
            first={v.url === firstInterview?.dataset.url}
            search={searchValue}
            tagFilter={tagFilter}
            setTagFilter={updateTagFilter}
            setHelperTarget={(elem) => setHelperTarget(elem)}
          />
        ))}
      </div>
    </>
  );
}

const Tag = ({ value, tagFilter, setTagFilter }) => {
  return (
    <span
      className={cx(
        'inline-block rounded-full bg-gray-300 px-2 py-0.5 text-xs mr-1 mb-1 cursor-pointer hover:bg-gray-400',
        { 'bg-green-400 hover:bg-green-500': tagFilter === value }
      )}
      onClick={() => setTagFilter(tagFilter === value ? null : value)}
    >
      {value}
    </span>
  );
};
