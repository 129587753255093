import React from 'react';
import cx from 'classnames';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import Splash from './Splash';
import Home from './pages/Home';
import Interviews from './pages/Interviews';
import Team from './pages/Team';
import About from './pages/About';
import SchismLogo from '../assets/schism.png';

export default function App() {
  const location = useLocation();
  const [started, setStarted] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [showNavBackground, toggleNavBackground] = React.useState(false);
  const [showNav, toggleNav] = React.useState(true);
  const [showNavMenu, toggleNavMenu] = React.useState(false);

  const handleScroll = (e) => {
    if (window.scrollY > 0) {
      toggleNavBackground(true);
    } else {
      toggleNavBackground(false);
    }
  };

  const handleResize = (e) => {
    setWidth(window.innerWidth);
  };

  const onPlaying = () => {
    toggleNav(true);
    setStarted(true);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    setTimeout(() => {
      setStarted(true);
    }, 5000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='font-body text-gray-900 bg-gray-100'>
      <nav
        className={cx(
          'nav',
          'w-full h-12',
          'fixed top-0 left-0 z-50',
          'transition duration-1000',
          'text-gray-100 text-sm',
          {
            'bg-black': showNavBackground || location.pathname !== '/',
            'opacity-0 pointer-events-none':
              width >= 520 && !started && location.pathname === '/' && window.scrollY === 0,
          }
        )}
      >
        {width < 520 ? (
          <div className='h-full px-6 flex justify-end items-center'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 w-6 cursor-pointer'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              onClick={() => toggleNavMenu(!showNavMenu)}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M4 6h16M4 12h16M4 18h16'
              />
            </svg>
          </div>
        ) : null}
        <div
          className={cx({
            'px-6 h-full mx-auto my-0 flex justify-between items-center': width >= 520,
            'p-6 absolute h-screen bg-black top-0 transition duration-400': width < 520,
            'right-0': width < 520 && showNavMenu,
            '-right-full hidden': width < 520 && !showNavMenu,
          })}
        >
          {width < 520 ? (
            <div className='flex justify-center mb-4'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 cursor-pointer'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                onClick={() => toggleNavMenu(false)}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </div>
          ) : null}
          <div className='flex items-center font-display uppercase'>
            <a
              href='/'
              className={cx('hover:text-green-400 transition duration-100', 'flex items-center', {
                'mb-4': width < 520,
              })}
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }, 40);
                toggleNavMenu(false);
              }}
            >
              This Machine <span className='ml-1 text-green-400'>Greens</span>
            </a>
          </div>
          <div
            className={cx('font-display', {
              'flex items-center': width >= 520,
              'flex flex-col': width < 520,
            })}
          >
            <Link
              to='/interviews'
              className={cx('hover:text-green-400 transition duration-100', {
                'px-4': width >= 520,
                'mb-4': width < 520,
              })}
              onClick={() => {
                setTimeout(() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }, 40);
                toggleNavMenu(false);
              }}
            >
              Search Interviews
            </Link>
            <Link
              to='/team'
              className={cx('hover:text-green-400 transition duration-100', {
                'px-4': width >= 520,
                'mb-4': width < 520,
              })}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                toggleNavMenu(false);
              }}
            >
              Team
            </Link>
            <Link
              to='/about'
              className={cx('hover:text-green-400 transition duration-100', {
                'px-4': width >= 520,
              })}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                toggleNavMenu(false);
              }}
            >
              About
            </Link>
          </div>
        </div>
      </nav>
      {location.pathname === '/' ? (
        <section>
          <Splash width={width} height={window.innerHeight} onPlaying={onPlaying} />
        </section>
      ) : null}
      <section className='w-full min-h-screen pt-16'>
        <div className='max-w-screen-lg mx-auto my-0 sm:px-6 py-8'>
          <Switch>
            <Route path='/interviews' component={Interviews} />
            <Route path='/team' component={Team} />
            <Route path='/about' component={About} />
            <Route path='/' component={Home} />
          </Switch>
        </div>
      </section>
      <section className='w-full h-40 flex flex-col justify-center items-center text-sm opacity-50'>
        <div className='flex items-center'>
          <div className='mr-2'>
            <a href='https://schism.film' className='flex items-center no-underline'>
              © <img src={SchismLogo} className='w-8 h-8' /> films
            </a>
          </div>
          •
          <div>
            <a href='https://alexklos.ca' className='ml-2 no-underline'>
              Site by Prohobo
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
