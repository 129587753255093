import React from 'react';

type Star = { x: number; y: number; z: number };

let prevTime;
let reqId;

export default function Starfield(props) {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    const stars = initStars();

    prevTime = new Date().getTime();
    render(ctx, stars, new Date().getTime(), props.width, props.height);
  }, []);

  React.useEffect(() => {
    cancelAnimationFrame(reqId);

    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    const stars = initStars();

    prevTime = new Date().getTime();
    render(ctx, stars, new Date().getTime(), props.width, props.height);
  }, [props.width, props.height]);

  return (
    <canvas className='absolute' ref={canvasRef} width={props.width} height={props.height} />
  );
}

function render(
  ctx: CanvasRenderingContext2D,
  stars: Star[],
  time: number,
  width: number,
  height: number
) {
  const elapsed = time - prevTime;
  prevTime = time;

  moveStars(stars, 0.008 * elapsed);
  ctx.clearRect(0, 0, width, height);

  const cx = width / 2;
  const cy = height / 2;

  for (const star of stars) {
    const x = cx + star.x / (star.z * 0.001);
    const y = cy + star.y / (star.z * 0.001);

    const distanceFromCenter = Math.sqrt(Math.pow(x - cx, 2) + Math.pow(y - (cy - 130), 2));

    if (x < 0 || x >= width || y < 0 || y >= height || distanceFromCenter < 125) {
      continue;
    }

    const d = star.z / 1000;
    const b = 1 - d * d;

    drawStar(ctx, x, y, b);
  }

  reqId = requestAnimationFrame(
    render.bind(this, ctx, stars, new Date().getTime(), width, height)
  );
}

function initStars() {
  const out = [];

  for (let i = 0; i < 1000; i++) {
    out.push({
      x: Math.random() * window.innerWidth - window.innerWidth / 2,
      y: Math.random() * window.innerHeight - window.innerHeight / 2,
      z: Math.random() * 1000,
    });
  }

  return out;
}

function drawStar(ctx: CanvasRenderingContext2D, x: number, y: number, brightness: number) {
  const intensity = brightness * 255;
  ctx.beginPath();
  ctx.fillStyle = `rgba(${intensity}, ${intensity}, ${intensity}, ${brightness})`;
  ctx.ellipse(x, y, Math.max(1, 3 * brightness), Math.max(1, 3 * brightness), 0, 0, Math.PI * 2);
  ctx.fill();
  ctx.closePath();
}

function moveStars(stars: Star[], distance: number) {
  for (const star of stars) {
    star.z -= distance;
    while (star.z <= 1) {
      star.z += 1000;
    }
  }
}
