import React from 'react';
import cx from 'classnames';
import fuzzysort from 'fuzzysort';
import VideoEmbed from './VideoEmbed';
import { addSeconds, subHours, differenceInSeconds, format } from 'date-fns';

export default function SearchableVideo(props) {
  const containerRef = React.useRef(null);
  const [jumpTo, setJumpTo] = React.useState(null);
  const [jumpPoints, setJumpPoints] = React.useState([]);
  const [showTooltip, setShowTooltip] = React.useState(null);
  const [searchPromise, setSearchPromise] = React.useState(null);

  const order = -jumpPoints.length - (props.tags.includes(props.tagFilter) ? 10 : 0);

  React.useEffect(() => {
    if (props.first) {
      if (jumpPoints.length) {
        props.setHelperTarget(containerRef.current.querySelector('.timeline'));
      } else {
        props.setHelperTarget(null);
      }
    }
  }, [props.first]);

  React.useEffect(() => {
    if (props.search) {
      if (searchPromise) {
        searchPromise.cancel();
      }

      setSearchPromise(
        fuzzysort.goAsync(props.search, props.srt, {
          key: 'content',
          threshold: -100,
          limit: 10,
        })
      );
    } else {
      setJumpPoints([]);
    }
  }, [props.search]);

  React.useEffect(() => {
    if (searchPromise) {
      const duration = props.duration;
      searchPromise.then((results) =>
        setJumpPoints(
          results
            .map((x: any) => {
              const dt = differenceInSeconds(
                subHours(addSeconds(new Date(0), duration), 1),
                x.obj.from
              );
              return {
                percent: ((duration - dt) / duration) * 100,
                value: duration - dt - 1,
                timestamp: x.obj.from,
                content: x.obj.content,
              };
            })
            .sort((a, b) => a.value - b.value)
            .filter((x) => x.value > 0 && x.value < duration)
            .reduce((accu, curr) => {
              if (accu.length) {
                if (Math.abs(accu[accu.length - 1].value - curr.value) > 30) {
                  accu.push(curr);
                }
              } else {
                accu.push(curr);
              }

              return accu;
            }, [])
        )
      );
    }
  }, [searchPromise]);

  return (
    <div
      ref={containerRef}
      data-url={props.url}
      className={cx('interview w-full flex flex-col', { 'col-span-2': props.expanded })}
      style={{
        order: order,
      }}
    >
      <div>
        <h3 className='mb-2 font-bold font-display'>{props.title}</h3>
        <VideoEmbed url={props.url} jumpTo={jumpTo} />
        <div className='mt-4 w-full mb-4 flex items-center'>
          <div className='flex-shrink-0'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4 mr-4'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
          </div>
          <div className='timeline w-full h-3 relative'>
            {jumpPoints.map((x, index) => (
              <div
                key={`jp_${index}`}
                className='absolute top-1/2 w-3 h-3 rounded-full bg-green-400 z-10 cursor-pointer hover:bg-green-500 transform -translate-x-1/2 -translate-y-1/2 transition duration-100'
                onClick={() => setJumpTo(x.value)}
                onMouseEnter={() => setShowTooltip(x.value)}
                onMouseLeave={() => setShowTooltip(null)}
                style={{ left: `${x.percent}%` }}
              >
                {showTooltip === x.value ? (
                  <div
                    className={cx(
                      'absolute z-20 w-40 bg-gray-100 text-gray-900 mt-6 transform -translate-x-1/2 rounded filter drop-shadow'
                    )}
                    style={{ left: `${x.percent}%` }}
                  >
                    <div className='flex flex-col items-center p-2'>
                      <div className='text-xs font-bold'>{format(x.timestamp, 'HH:mm:ss')}</div>
                      <div className='text-xs'>{x.content}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
            <div className={cx('absolute bg-gray-300 w-full h-full')}></div>
          </div>
        </div>
      </div>
      <div>
        {props.tags.map((t, index) => (
          <Tag
            key={`tag_${index}`}
            active={t === props.tagFilter}
            value={t}
            setTagFilter={props.setTagFilter}
          />
        ))}
      </div>
    </div>
  );
}

const Tag = ({ value, active, setTagFilter }) => {
  return (
    <span
      className={cx(
        'inline-block rounded-full bg-gray-300 px-2 py-0.5 text-xs mr-1 mb-1 cursor-pointer hover:bg-gray-400',
        { 'bg-green-400 hover:bg-green-500': active }
      )}
      onClick={() => setTagFilter(active ? null : value)}
    >
      {value}
    </span>
  );
};
