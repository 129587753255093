import React from 'react';
import * as THREE from 'three';
import cx from 'classnames';
import YTPlayer from 'yt-player';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass';
import BitcoinModel from './../assets/bitcoin.gltf';
import SunJpg from './../assets/sun.jpg';
import Donate from './Donate';
import Starfield from './Starfield';
import Lottie from 'lottie-react-web';
import SwanLogo from '../assets/swan.png';
import titleAnimation from './../assets/title.json';

const BLOOM_SCENE = 1;
let camera;
let renderer;

export default function Splash(props) {
  const [player, setPlayer] = React.useState(null);
  const [started, setStarted] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [paused, setPaused] = React.useState(false);
  const [showSwanLogo, setShowSwanLogo] = React.useState(false);
  const [finalComposer, setFinalComposer] = React.useState(null);
  const containerRef = React.useRef(null);
  const glitchPass = new GlitchPass();

  const onClickPlay = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setStarted(true);
    setPaused(false);
    props.onPlaying();
    finalComposer.addPass(glitchPass);
    setTimeout(() => {
      finalComposer.removePass(glitchPass);
      player.play();
      setPlaying(true);
    }, 1000);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setShowSwanLogo(true);
    }, 5000);

    const _player = new YTPlayer('#player', {
      modestBranding: true,
      host: 'https://www.youtube-nocookie.com',
    });

    _player.load('b-7dMVcVWgc');

    _player.on('paused', () => {
      setPaused(true);
    });

    _player.on('playing', () => {
      setPaused(false);
    });

    setPlayer(_player);

    (async () => {
      const bloomLayer = new THREE.Layers();
      bloomLayer.set(BLOOM_SCENE);

      const params = {
        exposure: 1,
        bloomStrength: 3,
        bloomThreshold: 0,
        bloomRadius: 0.8,
      };

      const darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
      const materials = {};

      const scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(40, props.width / props.height, 1, 200);
      camera.position.set(0, 0, 20);
      renderer = new THREE.WebGLRenderer({ antialias: true });

      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(props.width, props.height);
      renderer.toneMapping = THREE.ReinhardToneMapping;
      renderer.toneMappingExposure = Math.pow(params.exposure, 4.0);

      containerRef.current.appendChild(renderer.domElement);

      scene.add(new THREE.AmbientLight(0x404040));

      const renderPass = new RenderPass(scene, camera);

      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(props.width, props.height),
        1.5,
        1.4,
        0.85
      );
      bloomPass.threshold = params.bloomThreshold;
      bloomPass.strength = params.bloomStrength;
      bloomPass.radius = params.bloomRadius;

      const bloomComposer = new EffectComposer(renderer);
      bloomComposer.renderToScreen = false;
      bloomComposer.addPass(renderPass);
      bloomComposer.addPass(bloomPass);

      const finalPass = new ShaderPass(
        new THREE.ShaderMaterial({
          uniforms: {
            baseTexture: { value: null },
            bloomTexture: { value: bloomComposer.renderTarget2.texture },
          },
          vertexShader: `
            varying vec2 vUv;
            
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `,
          fragmentShader: `
            uniform sampler2D baseTexture;
            uniform sampler2D bloomTexture;

            varying vec2 vUv;

            void main() {
              gl_FragColor = (texture2D(baseTexture, vUv) + vec4(1.0) * texture2D(bloomTexture, vUv));
            }
          `,
          defines: {},
        }),
        'baseTexture'
      );
      finalPass.needsSwap = true;

      const finalComposer = new EffectComposer(renderer);
      finalComposer.addPass(renderPass);
      finalComposer.addPass(finalPass);
      setFinalComposer(finalComposer);

      const disposeMaterial = (obj) => {
        if (obj.material) {
          obj.material.dispose();
        }
      };

      const darkenNonBloomed = (obj) => {
        if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
          materials[obj.uuid] = obj.material;
          obj.material = darkMaterial;
        }
      };

      const restoreMaterial = (obj) => {
        if (materials[obj.uuid]) {
          obj.material = materials[obj.uuid];
          delete materials[obj.uuid];
        }
      };

      scene.traverse(disposeMaterial);

      const bg = await createBackground();
      scene.add(bg);

      const sun = await createSun();
      scene.add(sun);

      const bitcoin = await createBitcoinSymbol();
      scene.add(bitcoin);

      const animate = function () {
        setTimeout(() => {
          requestAnimationFrame(animate);
        }, 1000 / 60);

        sun.rotation.x += 0.003;
        sun.rotation.y += 0.0015;

        scene.traverse(darkenNonBloomed);
        bloomComposer.render();
        scene.traverse(restoreMaterial);
        finalComposer.render();
      };

      animate();
    })();
  }, []);

  React.useEffect(() => {
    camera.aspect = props.width / props.height;
    camera.updateProjectionMatrix();
    renderer.setSize(props.width, props.height);
  }, [props.width, props.height]);

  return (
    <div className='relative w-full overflow-hidden' style={{ height: `${props.height}px` }}>
      <div>
        <div
          ref={containerRef}
          className='absolute top-0 left-1/2 transform -translate-x-1/2'
        ></div>
        <Starfield width={props.width} height={props.height} />
        <a
          href='https://swanbitcoin.com'
          className={cx('absolute bottom-6 right-6 flex items-center transition duration-1000', {
            'opacity-0': !showSwanLogo,
          })}
        >
          <span className='text-white text-sm font-bold'>Presented by</span>
          <img src={SwanLogo} className='w-18 h-12' />
        </a>
        <div
          className={cx(
            'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
            'transition duration-200',
            'flex flex-col items-center',
            'w-full',
            {
              'opacity-0': started && !paused,
            }
          )}
        >
          <div className='mt-6 flex justify-center items-center'>
            <div
              className='relative pointer-events-none'
              style={{
                transform: 'translate(0, 10%)',
              }}
            >
              <div
                className='pointer-events-none'
                style={{ height: props.width < 520 ? '240px' : '360px' }}
              >
                <Lottie options={{ animationData: titleAnimation } as any} />
              </div>
            </div>
          </div>
          <button
            onClick={onClickPlay}
            className='play px-4 py-2 text-gray-900 bg-gray-200 hover:bg-green-400 font-bold font-display rounded transition duration-100 text-sm sm:text-lg'
          >
            <div className='flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 mr-2'
                viewBox='0 0 20 20'
                fill='currentColor'
              >
                <path
                  fillRule='evenodd'
                  d='M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='block sm:inline'>Watch the movie</span>
            </div>
          </button>
          <div className='mt-2'>
            <Donate />
          </div>
        </div>
      </div>
      <div
        className={cx(
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/4 flex flex-col items-center',
          {
            hidden: !started,
          }
        )}
      >
        <div id='player' className='mb-2'></div>
        <a href='/static/this_machine_greens_spanish_subs.srt' download>
          <button className='text-gray-200 hover:text-green-400 transition duration-100 flex items-center'>
            <span className='mr-1'>🇪🇸</span>
            <span className='text-xs'>Download Spanish subtitles</span>
          </button>
        </a>
      </div>
    </div>
  );
}

async function createSun() {
  const geometry = new THREE.IcosahedronGeometry(2, 2);
  const loader = new THREE.TextureLoader();
  const sunTexture = await loader.loadAsync(SunJpg);
  const material = new THREE.MeshPhongMaterial({
    map: sunTexture,
    color: 0xf8ba1a,
  });
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.set(0, 3, 0);
  sphere.rotation.set(0.2, 0, 0.3);
  sphere.layers.enable(BLOOM_SCENE);
  return sphere;
}

async function createBitcoinSymbol() {
  const bitcoin: any = await loadModel(BitcoinModel);
  bitcoin.material = new THREE.ShaderMaterial({
    vertexShader: `
      varying vec2 vUv;
            
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      } 
    `,
    fragmentShader: `
      uniform sampler2D baseTexture;
      uniform sampler2D bloomTexture;

      varying vec2 vUv;

      void main() {
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
      }
    `,
  });
  bitcoin.scale.set(0.16, 0.16, 0.16);
  bitcoin.rotation.set(0, 0, Math.PI - 0.3);
  bitcoin.position.set(0.05, 2.55, 3);
  return bitcoin;
}

function loadModel(model) {
  return new Promise((resolve, reject) => {
    const loader = new GLTFLoader();
    loader.load(
      model,
      function (gltf) {
        resolve(gltf.scene.children[0]);
      },
      undefined,
      (err) => reject(err)
    );
  });
}

function createBackground() {
  const geometry = new THREE.PlaneGeometry(64, 64);
  const material = new THREE.MeshBasicMaterial({ color: 0x15102a });
  const plane = new THREE.Mesh(geometry, material);
  plane.position.set(0, 0, -3.5);
  return plane;
}
