import React from 'react';

export default function About(props) {
  return (
    <div className='max-w-prose mx-auto my-0'>
      <h2 className='text-2xl font-display px-6 mb-4'>About</h2>
      <p className='mb-4 px-6'>
        <b>
          <i>This Machine Greens</i> explores a positive role for Bitcoin in the future of clean
          energy production.
        </b>{' '}
        Presented by Swan Bitcoin, the film explores Bitcoin’s relationship with energy — and
        shows that the image presented in the mainstream media may well have things upside-down.
      </p>
      <p className='mb-4 px-6'>
        Directed by award-winning British filmmaker Jamie King, and produced by Enrique Posner .
        The film premiered on Swan Bitcoin’s{' '}
        <a href='https://www.youtube.com/c/SwanSignal'>YouTube channel</a> and is now available at{' '}
        <a href='thismachinegreens.com'>thismachinegreens.com</a> and on YouTube.
      </p>
      <hr className='mb-4' />
      <p className='mb-4 px-6'>
        “The environmental argument made here is powerful,” says Cory Klippsten, CEO at Swan
        Bitcoin. He added, “This film addresses misperceptions about Bitcoin’s energy usage and
        makes the case that Bitcoin mining is actually a net positive for the environment.”
      </p>
      <p className='mb-4 px-6'>
        Starting from the premise that energy use goes hand in hand with progress, This Machine
        Greens sees a way forward in the production of more clean energy. Bitcoin, it argues,
        could help make that happen The film’s exploration of the early history of money also
        highlights Bitcoin’s role as part of a bigger story in the lineage of currencies
        structured around energy.
      </p>
      <p className='mb-4 px-6'>
        This Machine Greens features interviews with experts like Lyn Alden, Lead Economic Advisor
        for Swan Bitcoin; Meltem Demirors, CSO of Coinshares; Nic Carter, Partner of Castle Island
        Ventures; Caitlin Long, CEO of Avanti Financial Group; Alex Gladstein, CSO of Human Rights
        Foundation; Samson Mow, CSO of Blockstream; Greg Foss, CFO of Validus Power; and
        evangelist Hass McCook. They not only dispel key misconceptions about Bitcoin and Bitcoin
        mining, but create a compelling case that Bitcoin is a ‘greening machine’, setting the
        stage for conversations around the future of Bitcoin and its positive role in the
        environment.
      </p>
      <hr className='mb-4' />
      <p className='mb-4 px-6'>
        This Machine Greens was crowd-funded and the production team was paid almost entirely in
        Bitcoin --  with the peculiarity that Bitcoin’s price appreciation during the period of
        production resulted in a budget surplus in US dollar terms that will be applied towards
        King and Posner´s next documentary feature together, TRUST (trust.film)-- a film that will
        delve deep into Bitcoin’s pre-history and its increasing relevance amidst a crisis of
        authority and institutions.
      </p>
    </div>
  );
}
