import React from 'react';
import cx from 'classnames';

export default function Donate(props) {
  return (
    <div
      onClick={() => {
        const donateSection = document.getElementById('donate');
        // donateSection.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({ top: donateSection.offsetTop - 64, left: 0, behavior: 'smooth' });
      }}
      className={cx(
        'rounded relative transform donate px-4 py-2 font-bold cursor-pointer flex items-center',
        'font-display font-bold text-gray-200 hover:text-green-400 transition duration-100 text-sm'
      )}
    >
      {/* <span className='w-0 h-0 flex justify-center items-center p-3 rounded-full bg-yellow-400'>
        ₿
      </span> */}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className='h-4 w-4'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M13 5l7 7-7 7M5 5l7 7-7 7'
        />
      </svg>
      <span className='ml-2'>Help us fund our next Bitcoin film</span>
    </div>
  );
}
