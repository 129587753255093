import { parse } from 'date-fns';

export default [
  {
    title: 'Nic Carter',
    url: 'Serj_lhCxnM',
    srt: parseSRT(require('./assets/nic_carter.srt')),
    tags: ['macroeconomics', 'inflation', 'gold', 'state', 'money', 'energy', 'CBDCs'],
    duration: 1160,
  },
  {
    title: 'Friar Hass',
    url: 'hNBNseMOTSI',
    srt: parseSRT(require('./assets/friar_hass.srt')),
    tags: [
      'energy physics',
      'environmentalism',
      'alternative energy',
      'hard money',
      'climate change',
    ],
    duration: 1323,
  },
  {
    title: 'Samson Mow',
    url: 'CB_Eg8KxBdk',
    srt: parseSRT(require('./assets/samson_mow.srt')),
    tags: [
      'energy production',
      'nuclear fusion',
      'sustainable technology',
      'alternative energy',
      'hydropower',
    ],
    duration: 1433,
  },
  {
    title: 'Meltem Demirors',
    url: 'OSE8Skpimt4',
    srt: parseSRT(require('./assets/meltem_demirors.srt')),
    tags: [
      'ecological degradation',
      'gas industry',
      'environmentalism',
      'carbon credits',
      'energy efficiency',
    ],
    duration: 2890,
  },
  {
    title: 'Alex Gladstein',
    url: 'UMf2sv-UQ_M',
    srt: parseSRT(require('./assets/alex_gladstein.srt')),
    tags: [
      'world bank',
      'human rights',
      'petrodollar',
      'nakamoto consensus',
      'international economics',
      'kissinger',
      'nixon',
    ],
    duration: 2178,
  },
  {
    title: 'Caitlin Long',
    url: 'htlqphBlxp0',
    srt: parseSRT(require('./assets/caitlin_long.srt')),
    tags: [
      'el salvador',
      'capital',
      'lightning network',
      'Wyoming',
      'energy efficiency',
      'sustainable technology',
    ],
    duration: 1123,
  },
  {
    title: 'Adam Ortolf',
    url: 'rW0-OsPuw54',
    srt: parseSRT(require('./assets/adam_ortolf.srt')),
    tags: [
      'natural gas',
      'oil and gas industry',
      'methane mining',
      'energy physics',
      'environmentalism',
    ],
    duration: 2751,
  },
  {
    title: 'Magdalena Gronowska',
    url: 'pw6v1ajIYPI',
    srt: parseSRT(require('./assets/magdalena_gronowska.srt')),
    tags: ['sustainability', 'alternative energy', 'carbon emissions', 'clean tech'],
    duration: 1371,
  },
  {
    title: 'Justin Podhola',
    url: '3Iv6uTWhnT4',
    srt: parseSRT(require('./assets/justin_podhola.srt')),
    tags: [
      'green energy',
      'hydropower',
      'washington state',
      'gravity storage',
      'energy efficiency',
    ],
    duration: 629,
  },
  {
    title: 'Greg Foss',
    url: 'mL_Yr0pitYM',
    srt: parseSRT(require('./assets/greg_foss.srt')),
    tags: [
      'natural gas',
      'el salvador',
      'hydroelectrics',
      'alternative energy',
      'energy efficiency',
      'environmentalism',
      'fiat currency',
    ],
    duration: 2471,
  },
];

function parseSRT(srt) {
  srt = srt.replace('\ufeff', '');
  srt = srt.split('\r\n');
  srt = srt.reduce((accu, curr) => {
    if (curr.match(/^\d+$/)) {
      // Start new entry
      accu.push({});
    } else if (curr !== '') {
      const entry = accu[accu.length - 1];

      if (curr.match(/^\d{2}:\d{2}/)) {
        // Timestamps
        entry.from = parse(curr.split(' --> ')[0], 'HH:mm:ss,SSS', new Date(0));
        entry.to = parse(curr.split(' --> ')[1], 'HH:mm:ss,SSS', new Date(0));
      } else {
        // Speech content
        if (entry.content) {
          entry.content += ` ${curr.toLowerCase()}`;
        } else {
          entry.content = curr.toLowerCase();
        }
      }
    }

    return accu;
  }, []);
  return srt;
}
