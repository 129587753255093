import React from 'react';
import cx from 'classnames';

export default function SearchHelper(props) {
  const [hovering, setHovering] = React.useState(false);

  if (!props.target) {
    return null;
  }

  return (
    <div
      onClick={props.dismiss}
      className={cx(
        'absolute z-30 bg-yellow-200 text-gray-900 rounded filter drop-shadow cursor-pointer transition duration-1000'
      )}
      style={{
        top: props.target?.offsetTop,
        left: props.target?.offsetLeft - 62,
        transform: 'translate(0, -110%)',
      }}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div className='relative'>
        <div className={cx('absolute top-0 right-1 text-xs', { 'opacity-50': !hovering })}>✕</div>
        <div className='p-2 pr-8 text-sm'>
          <div className='sm:p-2 md:p-0'>
            <span>Videos show</span>
            <span className='inline-block w-0 h-0 p-1 bg-green-400 rounded-full mx-1'></span>
            <span>green dots in their timelines for matched keywords.</span>
            <br />
            <span>Click the dots to go to a specific point in the video.</span>
          </div>
        </div>
        <div className='arrow bottom'></div>
      </div>
    </div>
  );
}
