import React from 'react';
import bradmills from '../../assets/bradmills.jpg';

export default function Team(props) {
  return (
    <>
      <h2 className='text-2xl font-display px-6 mb-4'>Team</h2>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Director & Writer: Jamie King</h3>
          <p className='mb-4'>
            Jamie King is an award-winning British filmmaker and podcaster. Jamie's STEAL THIS
            FILM series (2006-2010) was one of the most downloaded documentaries of all time, and
            was screened at film festivals worldwide. From 2009-2014, Jamie created and ran VODO,
            a peer-to-peer film distribution platform which pioneered the crowdfunding model for
            creators; and from 2012-2014 he was Media Evangelist at BitTorrent Inc. His podcast,
            STEAL THIS SHOW, has interviewed many thought leaders in information technology and
            reached 2m+ listeners. Jamie holds a PhD for his study of the history of the Internet.
            He is currently in development with TRUST, his next documentary feature.
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F8c7fcd60-e579-47f4-bc65-ee5942826adb%2Fheadshot_edited.jpg?table=block&id=560e3b6c-9148-4d7c-bf80-b58e952629fd&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=580&userId=&cache=v2' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Co-writer: Mattias Rubenson</h3>
          <p className='mb-4'>
            Mattias Rubenson is an independent writer. His background is in theoretical
            philosophy, mathematics and the history of media. Since 2017, he's been the Party
            Secretary of the Swedish Pirate Party. In this capacity he has written and
            ghostwritten dozens of opinion pieces in Swedish newspapers on topics such as
            copyright and mass surveillance, as well as hundreds of news articles on European and
            Swedish digital politics.
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F58158925-833b-4df3-8b4e-ce8f1f0bb1af%2FUntitled.png?table=block&id=69d93aff-b416-4b24-868a-7df9e9626be0&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=480&userId=&cache=v2' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Co-editor: José Luis Arriaga</h3>
          <p className='mb-4'>
            José Luis Arriaga has over a decade of experience in film-related arts and
            institutions, producing and editing short and feature films; short and feature
            documentary films in addition to television and corporate productions.
          </p>
          <p className='mb-4'>
            He is a fully bilingual Spanish/English video editor who has worked internationally
            in Portugal, Spain, Colombia, Mexico, Tunisia, San Francisco, Los Angeles and New
            York with clients such as Iron Mountain Films, Secret Weapons Films, Saatchi &
            Saatchi, Prettybird, North of Now Group, Indigo Films, Stag Films and Discovery
            Education among others.
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F334ebc47-382a-4cc6-813f-908472f58e64%2FIMG-6976.jpg?table=block&id=d3d6e024-3030-4194-9c0b-de782ac1937b&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=3070&userId=&cache=v2' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>3D Artist: Mohammad Aki (aka Akishaqs)</h3>
          <p className='mb-4'>
            Aki is a passionate 3D artist based in Baheri, India. He loves minimal low-poly art,
            and has been working as a character designer for video games. He has worked on several
            indie projects like Plethora-Project's Common'hood, Escape Simulator by Pine Studios
            on Steam. He is most known for his Low-polygon character designs on Unity Assetstore.
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F286e8641-3371-442b-8589-a7e81c5acdc6%2FUntitled.png?table=block&id=4dd8fe99-d2ef-45fa-8990-c4243a16bff7&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=480&userId=&cache=v2' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Music & Sound Production: Nicolas Pacheco</h3>
          <p className='mb-4'>
            Nico is a sound designer, sound mixer, and music composer born in Bogota, Colombia,
            now based in Los Angeles, CA. After 10 years of experience with putting out records,
            and live playing with different bands, in 2013 he started getting involved with the
            audiovisual world when he worked as composer's assistant and music editor for a TV
            show that aired on one of Colombia’s biggest TV networks. After that experience, he
            decided to focus on becoming a composer for visual media. He moved to Los Angeles in
            2015 to study Film Scoring at UCLA Extension.
          </p>
          <p className='mb-4'>
            Soon after finishing his studies he began to build a parallel career as a sound
            designer and mixer. He then created his own full-service audio post-production and
            music composition company called Frametone, and since he´s been composing music, sound
            designing, and mixing for almost every single form of media out there.
          </p>
          <p className='mb-4'>
            He has worked in advertisement with clients like Careem, Stee Lauder, KFC, Infiniti,
            Barilla, GCDS, Los Angeles county fair, and more. In music videos, with artists like
            Bad Bunny, Julia Michaels, Kygo, Juice Wrld, Pomo, and more. In fiction and
            documentary on projects like YG Stay Dangerous, Birth Of A Pomegranate, Schism,
            Expedicion Sensorial, Verde Como el Oro, Beachwood, Just Married, Paul Revere is Dead,
            and more. See his entire work at{' '}
            <a href='www.frametonemusic.com'>www.frametonemusic.com</a>
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F3c75f336-1aba-4c40-85f9-99fbcbcad4d6%2FUntitled.png?table=block&id=7f720e72-812f-44d3-b1ce-03b384096e34&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=480&userId=&cache=v2' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Producer: Brad Mills</h3>
          <p className='mb-4'>
            <a href='https://bradmills.ca' target='_blank'>Brad Mills</a> is a life long entrepreneur, with a focus on product development & marketing. Involved with bitcoin since 2011 as a miner, entrepreneur & investor, Brad is a Value Maximalist at his core. Brad regularly involves himself in public discourse surrounding bitcoin. May that be through his podcast, <a href='https://www.bradmills.ca/podcast' target='_blank'> Magic Internet Money</a>, Clubhouse or Twitter spaces, or at one of many public Bitcoin Meetups.
          </p>
          <p className='mb-4'>
            In an effort to dispell misinformation surrounding bitcoin, Brad contributed to and co-produced This Machine Greens. The narrative around Bitcoin mining is that it is bad for the environment. This narrative must be stopped, and this documentary is exactly the sort of media that needs to be widely distributed to educate a wider group of people.
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src={bradmills} alt='Photo of Brad Mills Bitcoiner' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Producer: Enrique Posner</h3>
          <p className='mb-4'>
            Enrique Posner is a graduate of Chicago’s Northwestern University and with a degree in
            Communication Studies; he has over 15 film credits. With a long career working with
            industry greats like Milos Foreman, Guillermo del Toro and Pedro Almodovar, Posner has
            several Academy Award and Golden Globe nominated projects to his name. In 2001, Posner
            became a studio executive and served as Managing Director of Warner Bros Pictures
            International Spain and was responsible for the theatrical distribution and marketing
            of over 100 of the studio´s films including the Harry Potter and Matrix series amongst
            many others.
          </p>
          <p className='mb-4'>
            Enrique is currently developing a film version of the best-selling UK Young Adult
            novel <i>The Summer of Impossible Things</i> and has partnered with John Turturro,
            Robert DeNiro, Spike Lee, and Tribeca Films to produce <i>Howard Beach</i>. He also
            has a limited series in production, <i>Is There No Place On Earth For Me</i> based on
            the Pulitzer-Prize winning book.
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2F168e64c9-88d9-4acc-ae61-a32ec8a95210%2FUntitled.png?table=block&id=b92a50c7-58dd-42a4-891f-174c74a8e669&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=670&userId=&cache=v2' />
        </div>
      </div>
      <div className='md:flex px-6 mb-8'>
        <div className='md:w-2/3 md:pr-12'>
          <h3 className='font-display text-lg mb-4'>Producer: Joe Gallagher</h3>
          <p className='mb-4'>
            Joe Gallagher is a New York based actor and producer. Since catching eyes in 2019’s
            PLEDGE, Joe joined Stag Pictures as a creative executive and producer. The company
            currently has five projects in various stages of development. In 2022, Joe will star
            in two feature length films, a comedy directed by Daniel Robbins (PLEDGE), and a
            horror film directed by David R. Williams (RUST BELT DRILLER).
          </p>
        </div>
        <div className='md:w-1/3'>
          <img src='https://jamiek.notion.site/image/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fffd9b150-0572-4ad6-9b07-8670b2c02331%2FUntitled.png?table=block&id=b72576e2-64b3-4169-bb6f-6dd0a6c58c69&spaceId=dbffdc2e-fdea-4764-b729-3fe895f50a94&width=580&userId=&cache=v2' />
        </div>
      </div>
    </>
  );
}
