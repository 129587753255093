import React from 'react';

export default function Home() {
  return (
    <>
      <div className=''>
        <div className='separator'></div>
        <div className='bg-gray-200 p-6'>
          <div className='max-w-prose mx-auto my-0'>
            <h2 id='donate' className='text-xl font-display mb-6 pt-6'>
              Our next documentary project,{' '}
              <a href='https://trust.film'>
                <i>TRUST</i>
              </a>
              , looks at the role of Bitcoin in a world where authority, institutions and
              traditional centers of power are in crisis.
            </h2>
            <p className='mb-4'>
              The great social and economic changes happening today make Bitcoin more important
              than it's ever been.
            </p>
            <p className='mb-4'>
              This is a film about the decline of top-down power, and how Bitcoin can point to a
              world after authority.
            </p>
            <p className='mb-4'>
              <i>TRUST</i> digs down to the earliest roots of Bitcoin in another period of great
              distrust: the Cold War, where information scientists working in a 'trust no one'
              world came together to create modern cryptography - the same cryptography which
              today underpins the Bitcoin network.
            </p>
            <p className='mb-8'>
              Can this money, Bitcoin, now protect us in a world going through another crisis of
              trust?
            </p>
          </div>
          <div className='sm:flex sm:justify-center'>
            <div className='mb-8 sm:mb-0'>
              <a href='https://tallyco.in/s/3w1rbq/' className='button-link'>
                <button className='w-64 px-4 py-4 rounded bg-green-400 hover:bg-green-500 transition duration-100 flex justify-center items-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14'
                    />
                  </svg>
                  <span className='ml-2 font-display font-bold'>Donate on Tallycoin</span>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className='separator reverse'></div>
      </div>
    </>
  );
}
