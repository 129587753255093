import React from 'react';
import cx from 'classnames';
import YTPlayer from 'yt-player';

export default function VideoEmbed(props) {
  const [player, setPlayer] = React.useState(null);

  React.useEffect(() => {
    const _player = new YTPlayer(`#player_${props.url}`, {
      modestBranding: true,
      host: 'https://www.youtube-nocookie.com',
    });
    _player.load(props.url);
    setPlayer(_player);
  }, []);

  // React.useEffect(() => {
  //   if (player) {
  //     // player.seek(32);
  //     // player.play();
  //     console.log('hi', player.getDuration());
  //   }
  // }, [player, player?.getDuration()]);

  React.useEffect(() => {
    if (player) {
      player.play();
      player.pause();

      setTimeout(() => {
        player.seek(props.jumpTo);
        player.play();
      }, 1000);
    }
  }, [props.jumpTo]);

  return (
    <div className='relative bg-black bg-cover w-full h-60'>
      {/* <div
        className={cx(
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition duration-300',
          {
            'opacity-0 pointer-events-none': !!player,
          }
        )}
      >
        <div className='loader'></div>
      </div> */}
      <div id={`player_${props.url}`} className='w-full h-full'></div>
      {/* <iframe
        id={props.url}
        ref={iframe}
        className={cx('w-full h-full', { hidden: !player })}
        allow='encrypted-media'
        src={`${props.url}?api=1`}
        allowFullScreen
      /> */}
    </div>
  );
}
